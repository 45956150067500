import { Container, Row, Col, Tab, Nav } from "react-bootstrap";

import { VideoCard } from "./VideoCard";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import projImg1 from "../assets/img/board_photo_1.jpg";
import projImg2 from "../assets/img/board_photo_2.jpg";
import projImg3 from "../assets/img/board_photo_3.jpg";

import img2037 from "../assets/img/photo/new_photo/IMG_2037.PNG";
import img2038 from "../assets/img/photo/new_photo/IMG_2038.PNG";
import img2039 from "../assets/img/photo/new_photo/IMG_2039.PNG";
import img2040 from "../assets/img/photo/new_photo/IMG_2040.PNG";
import img2041 from "../assets/img/photo/new_photo/IMG_2041.PNG";
import img2042 from "../assets/img/photo/new_photo/IMG_2042.PNG";
import img2043 from "../assets/img/photo/new_photo/IMG_2043.PNG";
import img2044 from "../assets/img/photo/new_photo/IMG_2044.PNG";
import img2045 from "../assets/img/photo/new_photo/IMG_2045.PNG";

import videoImg1 from "../assets/img/video_1.png";
import videoImg2 from "../assets/img/video_2.png";
import videoImg3 from "../assets/img/video_2.png";


import video1 from "../assets/video/DSC_0001.mp4";
import video2 from "../assets/video/DSC_0002.mp4";
import video3 from "../assets/video/DSC_0003.mp4";


import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const images = [
    {
      original: img2037,
      thumbnail: img2037
    },
    {
      original: img2038,
      thumbnail: img2038
    },
    {
      original: img2039,
      thumbnail: img2039
    },
    {
      original: img2040,
      thumbnail: img2040
    },
    {
      original: img2041,
      thumbnail: img2041
    },
    {
      original: img2042,
      thumbnail: img2042
    },
    {
      original: img2043,
      thumbnail: img2043
    },
    {
      original: img2044,
      thumbnail: img2044
    },
    {
      original: img2045,
      thumbnail: img2045
    }


  ];

  const videos = [
    {
      thumbnail: videoImg1,
      original: videoImg1,
      embedUrl: video1,
      renderItem: VideoCard.bind(this),
    },

    {
      thumbnail: videoImg2,
      original: videoImg2,
      embedUrl: video2,
      renderItem: VideoCard.bind(this),
    },

    {
      thumbnail: videoImg3,
      original: videoImg3,
      embedUrl: video3,
      renderItem: VideoCard.bind(this),
    },

  ];

  return (
    <section className="project" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <Tab.Container id="projects-tabs" defaultActiveKey="photo">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="photo">Фото</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="tth">
                          Технические характеристики
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="creator">Производство</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="photo" >
                        <ImageGallery items={images} showThumbnails={false} />
                      </Tab.Pane>
                      <Tab.Pane eventKey="tth" style={{alignContent: 'center'}}>
                        <table className="table tth" style={{color : "white"}}>
                          {/* <tr>
                            <td>Длинна корпуса</td>
                            <td>4 м.</td>
                          </tr>
                          <tr>
                            <td>Габаритная длинна</td>
                            <td>4,5 м.</td>
                          </tr>
                          <tr>
                            <td>Объем мотора</td>
                            <td>0,75 л.</td>
                          </tr>
                          <tr>
                            <td>Мощность корпуса</td>
                            <td>25 л.с.</td>
                          </tr>
                          <tr>
                            <td>Килеватость на транце</td>
                            <td>9 градусов</td>
                          </tr> */}


                          <tr><td>Длина наибольшая</td><td>4 м</td></tr>
                          <tr><td>Длина габаритная</td><td>4,5 м</td></tr>
                          <tr><td>Ширина наибольшая</td><td>1,7 м</td></tr>
                          <tr><td>Ширина габаритная</td><td>1,8 м</td></tr>
                          <tr><td>Высота габаритная</td><td>1,05 м</td></tr>
                          <tr><td>Высота борта на миделе</td><td>0,7 м</td></tr>
                          <tr><td>Килеватость на транце</td><td>9 грд</td></tr>
                          <tr><td>Ёмкость бензобаков</td><td>66 л</td></tr>


                          <tr><td>Пассажировместимость</td><td>4 чел.</td></tr>

                          <tr><td>Тип двигателя</td><td>бензиновый, четырёхтактный, карбюраторный</td></tr>
                          <tr><td>Количество цилиндров</td><td>2 шт</td></tr>
                          <tr><td>Частота вращения коленчатого вала. При максимальной мощности</td><td>3600 об/мин.</td></tr>
                          <tr><td>Охлаждение двигателя</td><td>ринудительное, воздушное</td></tr>
                          <tr><td>Рабочий объём цилиндров</td><td>0,75 л</td></tr>
                          <tr><td>Мощность максимальная</td><td>27 л.с.</td></tr>
                          <tr><td>Тип движителя</td><td>водомёт, осевой, одноступенчатый</td></tr>
                          <tr><td>Диаметр рабочего колеса</td><td>185 мм</td></tr>
                        </table>
                      </Tab.Pane>

                      <Tab.Pane eventKey="creator" >
                        <ImageGallery
                          items={videos}
                          renderPlayPauseButton={() => ""}
                          showThumbnails={false}
                          showFullscreenButton={false}
                        />
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
