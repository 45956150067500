import Card from "react-bootstrap/Card";
import ReactPlayer from "react-player";

export const VideoCard = (cardItem) => {
  return (
    <Card style={{backgroundColor: "black"}}>
      <ReactPlayer id="react-player" url={cardItem.embedUrl} controls={true} width={"100%"} height={"100%"}/>
      <Card.Body
        style={{ alignContent: "center", textAlign: "center" }}
      ></Card.Body>
    </Card>
  );
};
