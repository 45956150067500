import { Container, Row, Col } from "react-bootstrap";
import "animate.css";

import headerImg from "../assets/img/board_1.png";
import logo from "../assets/img/logo.png";



export const Banner = () => {
  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          

          <Col xs={4} md={6} xl={4}>
            <div className={"animate__animated animate__fadeIn"}>
              <p
                style={{
                  fontSize: "3vw",
                  whiteSpace: "nowrap",
                }}
              >
                Водомётный катер
              </p>
              <img src={logo} alt="" style={{ width: "30vw" }} />
            </div>
          </Col>
          <Col xs={8} md={6} xl={8}>
            <div className={"animate__animated animate__zoomIn"}>
              <img
                src={headerImg}
                style={{
                  width: "30vw",
                  float: "right",
                }}
                alt="Header Img"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
